import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const headMeta = {
  title: 'Not found | PandevLaw',
};

function NotFound({ location }) {
  return (
    <Layout location={location} headMeta={headMeta}>
      <div className="container min-content-height">
        <div className="d-flex justify-content-center align-items-center min-content-height">
          <div className="text-center">
            <h1 className="text-hero">404</h1>
            <h5 className="mt-4">Page not found</h5>

            <Link to="/" className="btn btn-secondary mt-5">
              Back to the homepage
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NotFound;
